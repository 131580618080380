







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BasePasswordInput from '../core/BasePasswordInput.vue';
import BaseButton from '../widgets/BaseButton.vue';

@Component({
  name: 'SetPasswordForm',
  components: {
    BasePasswordInput,
    BaseButton
  }
})
export default class SetPasswordForm extends Vue {
  @Prop({ default: false }) readonly resetPasswordRoute!: boolean;

  password: string | null = null;

  confirmPassword: string | null = null;

  setPasswordSubmit(): void {
    this.$emit('setPassword', {
      password: this.password,
      confirmPassword: this.confirmPassword
    });
  }
}
