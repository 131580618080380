



























import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import UserAuthObject from '@improve/common-utils/src/model/UserAuthObject';
import BaseCenteredCard
  from '@improve/common-components/src/components/widgets/BaseCenteredCard.vue';
import SetPasswordForm from '@improve/common-components/src/components/forms/SetPasswordForm.vue';

@Component({
  name: 'SetPassword',
  components: {
    BaseCenteredCard,
    SetPasswordForm
  }
})
export default class SetPassword extends Vue {
  @Prop({ default: false }) readonly setPasswordRoute!: boolean;

  @Prop({ default: false }) readonly resetPasswordRoute!: boolean;

  @Getter isAuthenticated?: boolean;

  @Action confirmRegistration!: ActionMethod;

  @Action setPassword!: ActionMethod;

  @Action confirmForgotPassword!: ActionMethod;

  errorMessage: any = null;

  token: any = null;

  errorHappened = false;

  created(): void {
    this.validateToken();
  }

  validateToken(): void {
    if (!this.$route.query.token) {
      this.errorHappened = true;
      this.errorMessage = this.$t('error.invalidToken');
      return;
    }

    this.token = this.$route.query.token;
    if (this.setPasswordRoute) {
      this.confirmRegistration({ token: this.token });
    }
  }

  validatePassword = (password: string): boolean => (
    new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{9,})').test(password)
  );

  setPasswordSubmit(event: { password: string; confirmPassword: string }): void {
    this.errorMessage = null;

    if (!this.validatePassword(event.password)) {
      this.errorHappened = true;
      this.errorMessage = `${this.$t('page.setPassword.noMatch')}:`
        + `\n - ${this.$t('page.setPassword.nineOrMore')}`
        + `\n - ${this.$t('page.setPassword.oneCapital')}`
        + `\n - ${this.$t('page.setPassword.oneLowercase')}`
        + `\n - ${this.$t('page.setPassword.oneNumber')}`;
      return;
    }

    if (event.password !== event.confirmPassword) {
      this.errorHappened = true;
      this.errorMessage = this.$t('error.passwordsDontMatch');
      return;
    }

    if (this.setPasswordRoute) {
      this.setPasswordAfterRegistration(event.password)
        .then(() => this.proceedToLogin())
        .catch(() => this.processFailed());
    }
    if (this.resetPasswordRoute) {
      this.resetPasswordConfirmation(event.password, this.token)
        .then(() => this.proceedToLogin())
        .catch(() => this.processFailed());
    }
  }

  processFailed(): void {
    this.errorHappened = true;
    this.errorMessage = this.$t('error.general');
  }

  proceedToLogin(): void {
    this.$router.push({ name: 'Login' });
  }

  setPasswordAfterRegistration(password: string): Promise<UserAuthObject> {
    return this.setPassword({
      password
    });
  }

  resetPasswordConfirmation(password: string, token: string): Promise<UserAuthObject> {
    return this.confirmForgotPassword({
      password,
      token
    });
  }
}
